<template>
  <div class="modal-wrapper" v-if="showModal">
    <!-- 
      TODO : GENEL YAPIDA KULLANILMADIĞI İÇİN COMMENT HALİNE GETİRİLDİ.
    <div class="modal-header">
        
      <VueButton :ghost="true" as="a" @click="cancel()"
        ><VueText weightLevel="3" sizeLevel="4" color="white-100">Vazgeç</VueText></VueButton
      >
    </div>
    -->
    <div v-if="datePicker">
      <smooth-picker ref="smoothPicker" :data="dates" />
    </div>
    <div v-if="textPicker" class="filter-popup">
      <VueListItem
        @click="onRowSelected(item, index)"
        :text="item"
        v-for="(item, index) in textArray"
        :key="item.id"
        :icon="getIcon(index)"
        :contentAlignment="constants.flexAlignment.between"
      ></VueListItem>
      <VueButton
        :ghost="true"
        class="approve-btn"
        as="a"
        @click="textPicker ? confirmText() : confirm()"
        ><VueText weightLevel="3" sizeLevel="5" color="#23303d">Uygula</VueText></VueButton
      >
    </div>
  </div>
</template>

<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { SmoothPicker } from 'vue-smooth-picker';
import 'vue-smooth-picker/dist/css/style.css';

export default {
  name: 'BrandPickerModal',
  components: { VueButton, VueText, SmoothPicker, VueListItem },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    datePicker: {
      type: Boolean,
      default: false,
    },
    textPicker: {
      type: Boolean,
      default: false,
    },
    textArray: {
      type: Array,
    },
    filterType: {
      type: String,
      default: 'year',
    },
    selectedDay: {},
    selectedMonth: {},
    selectedYear: {},
  },
  data() {
    return {
      dates: [],
      selectedText: null,
      selectedIndex: '',
      // textList: [
      //   {
      //     currentIndex: 0,
      //     flex: 1,
      //     list: [],
      //     textAlign: 'center',
      //     className: 'item-group',
      //   },
      // ],
    };
  },
  beforeMount() {
    const months = [...Array(12)].map((d, i) => i + 1);
    const days = [...Array(31)].map((d, i) => i + 1);
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 1923; i <= currentYear; i++) {
      years.push(i);
    }
    this.dates = [
      ...[
        {
          currentIndex: this.selectedDay - 1 || 0,
          flex: 3,
          list: days,
          textAlign: 'center',
          className: 'item-group',
        },
        {
          currentIndex: this.selectedMonth || 0,
          flex: 3,
          list: months,
          textAlign: 'center',
          className: 'item-group',
        },
        {
          currentIndex: parseInt(this.selectedYear - 1923) || parseInt((currentYear - 1923) / 2),
          flex: 3,
          list: years,
          textAlign: 'center',
          className: 'item-group',
        },
      ],
    ];
  },
  methods: {
    getIcon(index) {
      return index === this.selectedIndex ? ICON_VARIABLES['checked'] : this.itemIcon;
    },
    confirm() {
      const currentIndexList = this.$refs.smoothPicker.getCurrentIndexList();
      let day = this.dates[0].list[currentIndexList[0]];
      let month = this.dates[1].list[currentIndexList[1]];
      let year = this.dates[2].list[currentIndexList[2]];
      let date = new Date(year, month - 1, day);
      const convertedDate = date.toISOString();
      this.$emit('getDate', convertedDate);
    },
    confirmText() {
      const selectedText = this.selectedText;
      let id = this.textList[0].list.indexOf(selectedText) + 1;
      this.$emit('getText', selectedText, id, this.filterType);
    },
    onRowSelected(event, index) {
      this.selectedIndex = index;
      this.selectedText = event;
    },
    cancel(e) {
      this.$emit('click', e);
    },
  },
  created() {},
  computed: {
    icon() {
      return {
        checked: ICON_VARIABLES.checked,
        circle: ICON_VARIABLES.circle,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    textList() {
      return [
        {
          currentIndex: 0,
          flex: 1,
          list: this.textArray && this.textArray.length ? [...this.textArray] : [],
          textAlign: 'center',
          className: 'item-group',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.modal-wrapper {
  position: absolute;
  top: 60px;
  left: 50%;
  z-index: 40;
  width: 100%;
  width: 300px;
  border-radius: 2px;
  box-shadow: 0 -8px 16px 0 rgba(55, 70, 95, 0.07);
  border: solid 1px #b4c2d3;
  background-color: #ffffff;
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    border-top: solid 1px #b4c2d3;
    border-left: solid 1px #b4c2d3;
    background: #ffffff;
    position: absolute;
    top: -8px;
    left: 50%;
    margin-left: -15px;
    z-index: 999;
  }
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: palette-space-level('15') palette-space-level('20');
  width: 100%;
  background-color: palette-color-level('grey', '40');
}

.filter-popup {
  /deep/.single-list-item {
    padding: 16px 28px;
    color: #23303d;
    cursor: pointer;
    span {
      font-size: 16px;
    }
  }
  /deep/.approve-btn {
    display: block;
    width: 181px;
    margin: 30px auto;
    border-radius: 31.2px;
    border: solid 1.5px #b4c2d3;
    background-color: #ffffff;
    text-align: center;
    padding: 13px 0 !important;
    &:hover {
      display: block;
      width: 181px;
      margin: 30px auto;
      border-radius: 31.2px;
      border: solid 1.5px #b4c2d3 !important;
      background-color: #ffffff;
      text-align: center;
      padding: 13px 0 !important;
    }
  }
}
</style>
