import { render, staticRenderFns } from "./BrandPickerModal.vue?vue&type=template&id=a2bd22ea&scoped=true&"
import script from "./BrandPickerModal.vue?vue&type=script&lang=js&"
export * from "./BrandPickerModal.vue?vue&type=script&lang=js&"
import style0 from "./BrandPickerModal.vue?vue&type=style&index=0&id=a2bd22ea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2bd22ea",
  null
  
)

export default component.exports