<template>
  <component
    :is="as"
    :to="to"
    class="single-list-item"
    @click="click"
    :event="event"
    :class="cssClasses.itemClasses"
  >
    <VueText as="span" style="lineHeight: 25px" :sizeLevel="textSize">{{ text }}</VueText>
    <div class="menu-icon-wrapper">
      <VueIcon
        v-if="icon"
        :iconColor="iconColor"
        :width="icon.width"
        :height="icon.height"
        :iconName="icon.name"
        :class="cssClasses.iconClasses"
      />
    </div>
    <VueBadge v-if="badge" :color="badgeColor" :radius="radius" :class="cssClasses.badgeClasses">
      {{ count }}
    </VueBadge>
  </component>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueBadge from '@/components/shared/VueBadge/VueBadge.vue';

export default {
  name: 'VueListItem',
  data() {
    return {};
  },
  computed: {
    cssClasses() {
      const iconClasses = [this.$style.icon];
      const itemClasses = [
        this.$style[`item-content-justify-${this.contentAlignment}`],
        this.$style.item,
      ];
      const badgeClasses = [this.$style.badge];
      this.borderPosition === 'top'
        ? itemClasses.push(this.$style['border-top'])
        : itemClasses.push(this.$style['border-bottom']);

      return {
        itemClasses,
        iconClasses,
        badgeClasses,
      };
    },
  },
  components: { VueIcon, VueText, VueBadge, VueInput },
  props: {
    as: {
      type: String,
      default: 'div',
    },
    event: {
      type: String,
    },
    to: {
      type: String,
      default: '/',
    },
    text: {
      type: String,
    },
    borderPosition: {
      type: String,
      default: 'bottom',
    },
    icon: {
      type: Object,
    },
    iconColor: {
      type: String,
    },
    badge: {},
    badgeColor: {
      type: String,
    },
    count: {},
    radius: {},
    contentAlignment: {
      type: String,
      default: FLEX_JUSTIFIES.between,
    },
    textSize: {
      type: String,
      default: '8',
    },
  },
  methods: {
    click(event, index) {
      this.$emit('click', event, index);
    },
  },
};
</script>

<style module lang="scss">
@import '~@/styles/styles';
.icon,
.badge {
  margin-right: palette-space-level('20');
}

.badge {
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: palette-space-level('5');
  color: palette-color-level('white', '100');
}

.item {
  font-size: palette-font-size-level('6');
  color: palette-color-level('grey', '40');
  font-weight: palette-font-weight-level('1');
  padding: palette-space-level('18') 0;
  display: flex;
  text-decoration: none;
  cursor: pointer;
}
.border-bottom {
  border-bottom: 0.5px solid rgba(palette-color-level('grey', '20'), 0.6);
}
.border-top {
  border-top: 0.5px solid rgba(palette-color-level('grey', '20'), 0.6);
}
@each $variation, $justify in $palette-flex-justifies {
  .item-content-justify-#{$justify} {
    justify-content: $justify;
  }
}
</style>
