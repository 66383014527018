<template>
  <div id="divMyWalletDetailWrapper" class="wallet-detail-wrapper">
    <transition appear appear-active-class="fade-enter-active">
      <section class="section cards">
        <div class="section-header">
          <div class="content-layout-fixer">
            <div class="section-header-content">
              <VueText sizeLevel="14" weightLevel="4">{{
                pointFormatter(getCurrentPoints)
              }}</VueText>
              <VueText color="grey-30" sizeLevel="11">Puan</VueText>
            </div>
          </div>
        </div>
      </section>
    </transition>
    <div class="content-layout-fixer">
      <div class="detail-content">
        <transition appear appear-active-class="fade-enter-active">
          <section class="section year-select-wrapper">
            <div @click="openFilter('year')" class="year-select">
              <VueText sizeLevel="5" weightLevel="2" color="#79838e"
                ><span>Yıl : {{ filterOptions.year.current }}</span></VueText
              ><VueIcon
                class="icon-caret"
                :iconName="icons.chevronDownAlt.name"
                :width="icons.chevronDownAlt.width"
                :height="icons.chevronDownAlt.height"
                color="#79838e"
              ></VueIcon>
            </div>
            <div ref="year" class="year-select-modal" v-if="filterOptions.year.show">
              <BrandPickerModal
                class="year-modal"
                refreshData
                :textPicker="true"
                :showModal="filterOptions.year.show"
                :textArray="getYears"
                filterType="year"
                @getText="getFilteredItems"
              ></BrandPickerModal>
            </div>
          </section>
        </transition>
        <transition appear appear-active-class="fade-enter-active">
          <section class="section cards-table">
            <table>
              <thead>
                <tr>
                  <th class="partner-wrapper" width="25%">
                    <div class="partner" @click="openFilter('partner')">
                      <VueText sizeLevel="6" weightLevel="2" color="grey-30"
                        ><span>Partner</span></VueText
                      ><VueIcon
                        :iconName="icons.chevronDownAlt.name"
                        :width="icons.chevronDownAlt.width"
                        :height="icons.chevronDownAlt.height"
                        color="#79838e"
                      ></VueIcon>
                    </div>
                    <div ref="partner" class="partner-modal" v-if="filterOptions.partner.show">
                      <BrandPickerModal
                        refreshData
                        :textPicker="true"
                        :showModal="filterOptions.partner.show"
                        :textArray="filterOptions.partner.data"
                        filterType="partner"
                        @getText="getFilteredItems"
                      ></BrandPickerModal>
                    </div>
                  </th>
                  <th @click="sortTable('date')" width="25%">
                    <VueText sizeLevel="6" weightLevel="2" color="grey-30">Tarih</VueText
                    ><VueIcon
                      v-show="sortOptions.order == 'desc'"
                      :iconName="icons.chevronDownAlt.name"
                      :width="icons.chevronDownAlt.width"
                      :height="icons.chevronDownAlt.height"
                      color="#79838e"
                    ></VueIcon>
                    <VueIcon
                      v-show="sortOptions.order == 'asc'"
                      :iconName="icons.chevronUpAlt.name"
                      :width="icons.chevronUpAlt.width"
                      :height="icons.chevronUpAlt.height"
                      color="#79838e"
                    ></VueIcon>
                  </th>
                  <th class="details-wrapper" width="30%">
                    <div class="details" @click="openFilter('details')">
                      <VueText sizeLevel="6" weightLevel="2" color="grey-30"
                        ><span>İşlem</span></VueText
                      ><VueIcon
                        :iconName="icons.chevronDownAlt.name"
                        :width="icons.chevronDownAlt.width"
                        :height="icons.chevronDownAlt.height"
                        color="#79838e"
                      ></VueIcon>
                    </div>
                    <div ref="details" class="details-modal" v-if="filterOptions.details.show">
                      <BrandPickerModal
                        :textPicker="true"
                        refreshData
                        :showModal="filterOptions.details.show"
                        :textArray="filterOptions.details.data"
                        filterType="details"
                        @getText="getFilteredItems"
                      ></BrandPickerModal>
                    </div>
                  </th>
                  <th width="20%">
                    <VueText sizeLevel="6" weightLevel="2" color="grey-30">Tutar</VueText>
                  </th>
                </tr>
              </thead>

              <tbody>
                <transition
                  appear
                  appear-active-class="fade-enter-active"
                  v-for="(item, i) in filteredItems"
                  :key="`log_${i}_${item.date}`"
                >
                  <tr>
                    <td width="20%">
                      <div
                        v-for="p in item.partners"
                        :key="p.id"
                        class="cards-table-image"
                        :style="{
                          backgroundImage: p.image ? `url(${p.image})` : '',
                          backgroundSize: p.image ? '90%' : 'unset',
                        }"
                      ></div>
                    </td>
                    <td class="cards-table-date" width="20%">
                      <VueText sizeLevel="6" weightLevel="2" color="grey-40">{{
                        item.date | toLocaleDateTimeString({ format: dateFormat })
                      }}</VueText>
                    </td>
                    <td class="cards-table-process" @click="showProcessInfo(item)">
                      <VueIcon
                        class="icon-process"
                        v-if="item.processType.processTypeCode == 6"
                        :width="icons.info.width"
                        :height="icons.info.height"
                        :iconName="icons.info.name"
                      />
                      <VueText sizeLevel="6" weightLevel="2" class="text-style">{{
                        item.details
                      }}</VueText>
                    </td>
                    <td class="cards-table-amount" width="20%">
                      <VueIcon
                        class="icon-process"
                        v-if="item.isPointDetail"
                        :iconName="icons.points.name"
                        :width="icons.points.width"
                        :height="icons.points.height"
                        :color="item.exchangePoints >= 0 ? '#6dd400' : '#e02020'"
                      ></VueIcon>
                      <VueText
                        class="text-style"
                        sizeLevel="6"
                        weightLevel="4"
                        color="green-10"
                        v-if="item.exchangePoints >= 0"
                        >+{{
                          `${pointFormatter(item.exchangePoints) +
                            (!item.isPointDetail ? ' ₺' : '')}`
                        }}</VueText
                      >
                      <VueText
                        sizeLevel="4"
                        weightLevel="4"
                        color="red-30"
                        class="text-style"
                        v-else
                        >{{
                          `${pointFormatter(item.exchangePoints) +
                            (!item.isPointDetail ? ' ₺' : '')}`
                        }}</VueText
                      >
                    </td>
                  </tr>
                </transition>
              </tbody>
            </table>
          </section>
        </transition>
        <BrandButton
          :size="sizes.xxLarge"
          :outlined="true"
          class="convert-button"
          type="submit"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click.prevent="handleBack"
          ><VueText weightLevel="3" color="red-10">GERİ DÖN</VueText></BrandButton
        >
      </div>
      <BrandInfoPopup :show.sync="processTypeModal.show">
        <div class="process-info-modal" v-if="processTypeModal.data">
          <h2>{{ processTypeModal.data.processTypeDescription }}</h2>
          <table>
            <thead>
              <tr>
                <th>Kriter</th>
                <th>Performans</th>
                <th>Puan Değeri</th>
                <th>Kazanılan Puan</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="crit in processTypeModal.data.calculatedCriterias"
                :key="crit.calculatedCriteriaIndex"
              >
                <td>{{ crit.calculatedCriteriaName }}</td>
                <td>{{ crit.calculatedCriteriaPerform }}</td>
                <td>{{ crit.calculatedCriteriaMaxPoint }}</td>
                <td>{{ crit.calculatedCriteriaPoint }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td>TOPLAM</td>
                <td>{{ processTypeModal.data.sumMaxPoint }}</td>
                <td>{{ processTypeModal.data.sumPoint }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </BrandInfoPopup>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import BrandPickerModal from '@/components/brand/Modals/BrandModal/BrandPickerModal';
import BrandButton from '@/components/brand/BrandButton/BrandButton';

import StorageProps from '@/mixins/storageProps';
import dateUtils from '@/mixins/dateUtils';
import { groupBy, filterBy, sortBy, sumBy } from '@/mixins/arrayUtils';

import { Finance } from '@/services/Api/index';

import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants';
import { numberFormatter } from '@/utils/publicHelper';

import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesMyWallets from '@/router/routes/secure/RoutesMyWallets';
import RoutesRoot from '@/router/routes/RoutesRoot';

import BrandInfoPopup from '@/components/brand/Modals/BrandModal/BrandInfoPopup.vue';

export default {
  name: 'WalletsDetail',
  mixins: [StorageProps, dateUtils],
  components: {
    VueText,
    VueIcon,
    BrandPickerModal,
    BrandButton,
    BrandInfoPopup,
  },
  data() {
    let defaultPartner = '';
    if (this.$route.query && this.$route.query.partner) {
      defaultPartner = this.$route.query.partner;
    }
    return {
      dateFormat: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      },
      walletsHomeURL: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}/${RoutesMyWallets.WalletsHome.path}`,
      isDateFilterOpen: false,
      isCategoryFilterOpen: false,
      pointLogs: [],
      sortOptions: { key: 'date', order: 'desc' },
      filterOptions: {
        year: { show: false, data: [], current: new Date().getFullYear() },
        details: { show: false, data: [] },
        partner: { show: false, data: [] },
        keys: { details: '', partner: defaultPartner },
      },
      processTypeModal: { show: false, data: null },
    };
  },
  created() {
    window.addEventListener('click', e => {
      if (this.$refs.partner) {
        if (
          this.filterOptions.partner.show &&
          !e.target.classList.contains('partner') &&
          !this.$refs.partner.contains(e.target)
        ) {
          if (
            e.target.classList.contains('single-list-item') ||
            e.target.classList.contains('menu-icon-wrapper') ||
            e.target.tagName.toLowerCase() === 'span' ||
            e.target.tagName === 'circle' ||
            e.target.tagName === 'path' ||
            e.target.tagName === 'svg'
          ) {
            this.filterOptions.partner.show = true;
          } else {
            this.filterOptions.partner.show = false;
          }
        }
      }

      if (this.$refs.details) {
        if (
          this.filterOptions.details.show &&
          !e.target.classList.contains('details') &&
          !this.$refs.details.contains(e.target)
        ) {
          if (
            e.target.classList.contains('single-list-item') ||
            e.target.classList.contains('menu-icon-wrapper') ||
            e.target.tagName.toLowerCase() === 'span' ||
            e.target.tagName === 'circle' ||
            e.target.tagName === 'path' ||
            e.target.tagName === 'svg'
          ) {
            this.filterOptions.details.show = true;
          } else {
            this.filterOptions.details.show = false;
          }
        }
      }

      if (this.$refs.year) {
        if (
          this.filterOptions.year.show &&
          !e.target.classList.contains('year-select') &&
          !this.$refs.year.contains(e.target)
        ) {
          if (
            e.target.classList.contains('single-list-item') ||
            e.target.classList.contains('menu-icon-wrapper') ||
            e.target.tagName.toLowerCase() === 'span' ||
            e.target.tagName === 'circle' ||
            e.target.tagName === 'path' ||
            e.target.tagName === 'svg'
          ) {
            this.filterOptions.year.show = true;
          } else {
            this.filterOptions.year.show = false;
          }
        }
      }
    });
  },
  beforeMount() {
    this.setTableData();
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
    icons() {
      return ICON_VARIABLES;
    },
    getCurrentPoints() {
      if (this.filterOptions.keys.partner && this.filterOptions.keys.partner !== 'Hepsi') {
        return sumBy(this.customerDetail.partners, 'point', {
          key: 'code',
          value: this.filterOptions.keys.partner,
        });
      } else {
        return this.customerPoints;
      }
    },
    filteredItems() {
      let filteredData = this.pointLogs;

      for (let [key, value] of Object.entries(this.filterOptions.keys)) {
        if (key == 'details' && value) {
          if (value != 'Hepsi') {
            filteredData = filterBy(filteredData, key, value);
          }
        }

        if (key == 'partner' && value) {
          if (value != 'Hepsi') {
            filteredData = filteredData.filter(
              item => item.partners.filter(x => x.partnerCode === value).length > 0,
            );
          }
        }
      }
      if (this.sortOptions.key) {
        filteredData = sortBy(filteredData, this.sortOptions.key, this.sortOptions.order);
      }

      return filteredData;
    },
    getYears() {
      let allYears = [];
      let nowYear = new Date().getFullYear();

      for (let i = nowYear; i > nowYear - 4; i--) {
        allYears.push(i.toString());
      }
      return allYears;
    },
  },
  methods: {
    showProcessInfo(item) {
      if (item.processType.processTypeCode != 6) {
        return;
      }
      this.processTypeModal.data = Object.assign({}, item.processType);
      this.processTypeModal.show = true;
    },
    handleBack() {
      this.$router.push(this.walletsHomeURL);
    },
    sortTable(key) {
      this.closeFilter();
      this.sortOptions.key = key;
      this.sortOptions.order = this.sortOptions.order == 'desc' ? 'asc' : 'desc';
    },
    setTableData() {
      Finance.getPointLogs(this.filterOptions.year.current).then(res => {
        if (res.data && res.data.Data && res.data.Data.result) {
          const {
            Data: { result },
          } = res.data;

          this.pointLogs = result;
          this.filterOptions.details.data = [
            'Hepsi',
            ...Object.keys(groupBy(this.pointLogs, 'details')),
          ];
          this.filterOptions.partner.data = ['Hepsi'];
          this.pointLogs.forEach(element => {
            element.partners.forEach(x => {
              if (!this.filterOptions.partner.data.includes(x.partnerCode)) {
                this.filterOptions.partner.data.push(x.partnerCode);
              }
            });
          });
        }
      });
    },
    openFilter(filterType) {
      let filterStatus = this.filterOptions[filterType].show;
      this.closeFilter();
      this.filterOptions[filterType].show = !this.filterOptions[filterType].show;
      if (filterStatus) {
        this.filterOptions[filterType].show = !this.filterOptions[filterType].show;
      }
      this.setBottomBarStatus(false);
    },
    closeFilter() {
      this.filterOptions.year.show = false;
      this.filterOptions.details.show = false;
      this.filterOptions.partner.show = false;
    },
    getFilteredItems(value, id, filterType) {
      this.filterOptions[filterType].show = false;
      if (filterType == 'year') {
        this.filterOptions.year.current = value;
        this.setTableData();
      }
      this.setBottomBarStatus(true);
      this.filterOptions.keys[filterType] = value;

      document.documentElement.style.overflow = 'auto';
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    pointFormatter(value) {
      return numberFormatter(value);
    },
    currencyFormatter(value) {
      return numberFormatter(value, 'TRY').replace('₺', '');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';

.details-wrapper {
  position: relative;
  .modal-wrapper {
    margin-left: -115px;
  }
}
.partner-wrapper {
  position: relative;
  .modal-wrapper {
    margin-left: -108px;
  }
}
.icon-process {
  position: relative;
  left: -10px;
  top: 5px;
}
.text-style {
  display: inline-block;
}
/deep/.menu-icon-wrapper {
  position: absolute;
  right: 0;
}
.wallet-detail-wrapper {
  padding-bottom: palette-space-level(30);
  .section {
    &.year-select-wrapper {
      position: relative;
      .year-select {
        display: inline-flex;
        align-items: center;
        padding: palette-space-level(10) palette-space-level(15);
        border: 1px solid #b4c2d3b4;
        border-radius: palette-space-level(5);
        margin-bottom: palette-space-level(5);
        background-color: #f8f9f9;
        cursor: pointer;
        p {
          padding-right: palette-space-level('5');
        }
      }
    }

    &-header {
      border-top: 1px solid palette-color-level('grey', '15');
      border-bottom: 1px solid palette-color-level('grey', '15');
      border-right: 1px solid palette-color-level('grey', '15');
      border-top-right-radius: 4px;
      background-color: palette-color-level('grey', '10');
      margin-top: palette-space-level('50');
      margin-bottom: palette-space-level('50');
      padding: palette-space-level('30') 0;

      & > .content-layout-fixer {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
      }

      &-content {
        display: flex;
        align-items: center;
        background: url('~@/assets/images/score.png') no-repeat left center;
        background-size: contain;
        padding-left: palette-space-level('45');
        height: 40px;

        p:first-child {
          position: relative;
          top: 4px;
        }

        p:last-child {
          position: relative;
          top: 8px;
          margin-left: palette-space-level('5');
        }
      }
    }
  }

  .cards {
    &-list {
      $self: &;
      background-image: linear-gradient(
        315deg,
        palette-color-level('fushia', '20'),
        palette-color-level('yellow', '30')
      );
      padding: 2px;
      border-radius: palette-radius-level('4');
    }
    &-table {
      margin-bottom: palette-space-level('100');
      table {
        width: 100%;
        border-top: 1px solid palette-color-level('grey', '15');
        border-bottom: 1px solid palette-color-level('grey', '15');
      }

      thead {
        th {
          text-align: center;
          background-color: palette-color-level('grey', '10');
          border-right: 1px solid palette-color-level('grey', '15');
          cursor: pointer;

          &:first-child {
            border-left: 1px solid palette-color-level('grey', '15');
          }
          .details,
          .partner {
            padding: palette-space-level('15') palette-space-level('10');
          }
          p {
            position: relative;
            top: 0px;

            &:not(:last-child) {
              margin-right: palette-space-level('8');
            }
          }
          svg {
            position: relative;
            top: 3px;
          }
          p,
          svg {
            display: inline-block;
          }
        }
      }
      tbody {
        td {
          padding: palette-space-level('20') palette-space-level('10');
          border-top: 1px solid palette-color-level('grey', '15');
          border-right: 1px solid palette-color-level('grey', '15');
          text-align: center;

          &:first-child {
            border-left: 1px solid palette-color-level('grey', '15');
          }

          // &.cards-table-amount {
          //   text-align: center;
          // }
          // &.cards-table-date {
          //   text-align: center;
          // }
          .cards-table-image {
            &:not(:first-child) {
              margin-top: 5px;
            }
          }
          &.cards-table-process {
            position: relative;
            max-width: 110px;
          }
        }
      }

      &-image {
        margin: auto;
        background-color: palette-color-level('white', '100');
        width: 80px;
        height: 80px;
        border: 1px solid palette-color-level('grey', '15');
        border-radius: palette-radius-level('1');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('~@/assets/bg/info-card-bg.png');
      }
    }
  }
  .process-info-modal {
    h2 {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: palette-space-level('20');
    }
    table {
      th,
      td {
        padding: 15px;
        border: 1px solid palette-color-level('grey', 18);
        &:nth-child(n + 2) {
          text-align: center;
        }
      }
      thead {
        font-size: 18px;
        th {
          background-color: palette-color-level('grey', 10);
        }
      }
      tbody {
        font-size: 18px;
        tr:nth-child(n + 2) {
          background-color: palette-color-level('grey', 10);
        }
      }
      tfoot {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
.detail-content {
  width: 832px;
  margin: auto;
}
.year-modal {
  left: -50px;
}
</style>
