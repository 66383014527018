<template>
  <component :is="as" :class="[$style.text, cssClass]" :style="cssStyles.badge" ref="badge">
    <slot />
  </component>
</template>
<script>
export default {
  name: 'VueBadge',
  props: {
    as: {
      type: String,
      default: 'div',
    },
    radius: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'white-100',
    },
  },
  computed: {
    cssClass() {
      const classes = [this.$style[`badge-${this.color}`]];

      return classes;
    },
    cssStyles() {
      const badge = {
        'border-radius': `${this.radius}px`,
      };
      return { badge };
    },
  },
};
</script>
<style scoped lang="scss" module>
@import '~@/styles/styles';

.badge {
  font-family: $font-family;
  font-size: inherit;
  font-weight: inherit;
  background-color: inherit;
}

@each $variation, $color in $palette-colors {
  .badge-#{$variation} {
    background-color: $color;
  }
}
</style>
